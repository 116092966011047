.contact {
  .contact-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2em 1em;
    background: linear-gradient(
      to bottom,
      #1a1a1a 0%,
      #343433 49%,
      #1a1a1a 100%
    );
    color: #fff;
  }
  .paper-content {
    padding: 40px;
  }
  .paper-container {
    padding: 10px;
    background-image: linear-gradient(
      to right,
      rgb(26, 26, 26) 0%,
      rgb(52, 52, 51) 49%,
      rgb(26, 26, 26) 100%
    );
    padding-bottom: 50px;
  }
}
