.home-no-user {
  .show-movie-detail-section-tras {
    justify-content: center;
  }
  .col-half-offset {
    margin-left: 3.33%;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 767px) {
    .col-half-offset {
      margin-left: 0;
    }
  }

  .btn-promotion {
    font-size: 1em !important;
  }

  .arrow-title {
    font-size: 1.8em !important;
    font-family: "Abel";
    margin-bottom: 20px !important;
    font-weight: 400;
    z-index: 1;
  }

  .margin-icons {
    margin-left: 10px;
    margin-right: 10px;
  }

  .padding-films {
    padding: 0.8em 0.6em 0.4em 0.8em;
  }
  @media screen and (min-width: 1500px) {
    .col-xxl-2 {
      -ms-flex: 0 0 16.6%;
      flex: 0 0 16.6%;
      max-width: 16.6%;
    }
  }
  .space-block {
    padding: 8rem 0 5rem 0;
  }
  .xiclos-movies {
    justify-content: space-around;
    flex-wrap: wrap;
    display: flex;
    align-content: flex-start;
    margin-left: 0;
  }
  .xiclos-movies-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
  li.slide-item-cine-club {
    margin: 20px 35px 3px 0px;
    width: 16rem;
    padding: 0px 0px !important;
  }
  .cine-club-title {
    background-image: url("../images/xiclos-slider-title-red.png");
    background-repeat: no-repeat;
    background-position: 0px 3px;
    padding-left: 1em;
    color: white;
    margin-left: 12rem;
    h4 {
      font-family: var(--xiclos-font-abel);
      font-size: 1.5em;
    }
  }
  .xiclo-title {
    i {
      font-size: 3em;
    }
    h3 {
      font-size: 1.5em;
    }
  }
  .main-slider-bold {
    font-family: var(--xiclos-helv-bold);
    color: #ffffff;
    font-size: 2em;
  }
  .main-slider-text {
    font-family: var(--xiclos-helv-bold);
    color: #ffffff;
    font-size: 1em;
  }
  .text-red {
    color: var(--xiclos-red);
    font-family: var(--xiclos-helv-bold);
  }
  #home-slider {
    p {
      margin: 20px 0;
      width: 75%;
      color: #ffffff;
    }
    h1.slider-text {
      padding-top: 0.5em;
      font-size: 5em;
      margin: 15px 0;
    }
    .home-no-usuario {
      width: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      height: 90vh;
      position: relative;
      z-index: 1;
      background-image: url("../images/home-sin-usuario.jpg");
      min-height: 90vh;
    }
  }
  .p1-catalogo {
    color: #ffffff;
    font-family: var(--xiclos-font-abel);
    font-size: 1em;
  }
  .p-catalogo {
    color: #ffffff;
    font-family: var(--xiclos-font-helv-normal);
    font-size: 1.3em;
  }
  .p-peliculas-marcaron {
    color: #ffffff;
    font-family: var(--xiclos-helv-normal);
    font-size: 1em;
    max-width: 866px;
    text-align: center;
  }
  .wrapper-movie-interest {
    .main-title {
      background: none !important;
      text-transform: capitalize;
      padding: 0 !important;
      position: relative !important;
    }
  }
  .wrapper-cineclub {
    .cineclub-main-title {
      background: none !important;
      text-transform: capitalize;
      padding: 0 !important;
      position: relative !important;
    }
  }
  .btn-subscribe {
    border: none !important;
    font-family: "Helvetica Neue Bold";
    font-size: 0.9em;
  }
  .cineclub-main-title {
    padding-left: 1em !important;
    font-size: 1.8em !important;
    font-family: var(--xiclos-font-abel);
    margin-bottom: 0px !important;
    font-weight: 400;
  }
  .arrow {
    margin-top: 3em;
    cursor: pointer;
    h5 {
      font-family: var(--xiclos-font-abel);
      &:hover {
        color: var(--iq-primary-hover);
      }
    }
  }
  #down {
    a {
      i {
        font-size: 4em;
        &:hover {
          color: red;
        }
      }
    }
  }
  .home-no-usuario {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 90%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(20, 20, 20, 1) 0%,
        rgba(36, 36, 36, 1) 35%,
        rgba(83, 100, 141, 0) 100%
      );
      z-index: 1;
      background: linear-gradient(
        64deg,
        rgba(0, 0, 0, 82%) 53%,
        rgba(20, 20, 20, 92%) 35%,
        rgba(83, 100, 141, 0) 0%
      );
      width: 100%;
      z-index: -1;
    }
  }
  #iq-directors {
    background-image: url("../images/bg_cineclub_section.jpg");
  }
  #directors-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
    margin-right: 3em;
    li {
      padding-right: 3em;
      margin-right: 2em;
      padding-bottom: 1em;
    }
    img {
      height: 9em;
      width: 9em;
    }
  }
  .cineclub-directors {
    background-image: url("../images/bg_cineclub_section.jpg");
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
  }
  .red-border {
    height: 12em;
    background: linear-gradient(
      193deg,
      rgb(235, 86, 74, 82%) 30%,
      rgba(20, 20, 20, 0%) 30%,
      rgba(83, 100, 141, 0) 0%
    );
  }
  .btn-free {
    border: solid 1px #ffffff;
  }
  .div-free-button {
    padding-right: 3em;
    padding-bottom: 1em;
    padding-top: 1em;
  }
  .catalog-background {
    padding: 0px 0 0px;
    background-color: #2d2d2d;
  }
  .btn-log-hover {
    background: var(--iq-primary-hover);
    color: var(--iq-primary-hover);
    transition: color 0.3s ease;
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #ffffff;
      border: 1px solid var(--iq-primary);
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transform-origin: 50%;
      transform-origin: 50%;
      transition: transform 0.3s ease-out;
      -webkit-transition: transform 0.3s ease-out;
      border-radius: 5px;
    }
    &:hover {
      color: var(--iq-white);
      &:before {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  .btn-hover {
    &:focus {
      color: var(--iq-white);
      color: var(--iq-white);
      box-shadow: none !important;
      &:before {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }
    }
    &:active {
      color: var(--iq-white);
      color: var(--iq-white);
      &:before {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }
    }
    background: var(--iq-primary-hover);
    // border: 1px solid #ffffff;
    color: var(--iq-white);
    transition: color 0.3s ease;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    border-radius: 5px;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--iq-primary);
      border: 1px solid var(--iq-primary);
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transform-origin: 50%;
      transform-origin: 50%;
      transition: transform 0.3s ease-out;
      -webkit-transition: transform 0.3s ease-out;
    }
    &:hover {
      color: var(--iq-white);
      &:before {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }
    }
  }
  .div-log-button {
    padding-right: 3em;
  }

  .no-user-header {
    background-image: url("../images/home-sin-usuario.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    max-height: 92vh;
    height: 92vh;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 92vh;
      background: linear-gradient(
        64deg,
        rgba(0, 0, 0, 82%) 53%,
        rgba(20, 20, 20, 92%) 35%,
        rgba(83, 100, 141, 0) 0%
      );
    }
    .arrow-catalogo {
      position: absolute;
      bottom: 0px;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  @media screen and (min-width: 769px) {
    .no-user-header {
      background-image: url("../images/home-sin-usuario.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      max-height: 100vh;
      height: 100vh;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100vh;
        background: linear-gradient(
          64deg,
          rgba(0, 0, 0, 82%) 53%,
          rgba(20, 20, 20, 92%) 35%,
          rgba(83, 100, 141, 0) 0%
        );
      }
      .arrow-catalogo {
        position: absolute;
        bottom: 0px;
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .xiclos-title {
    h1 {
      font-family: "Abel";
      font-size: 4em;
      margin-top: 1.6em;
    }
  }
  .xiclos-description {
    font-family: "Abel";
    font-size: 1.3em;
    margin: 0.6em 0;
    max-width: 600px;
    color: #fff;
  }
  .xiclos-price {
    margin: 1.7em 0 0 0;
    font-family: var(--xiclos-font-helv-bold);
    font-size: 1.7em;
    color: #fff;
    line-height: 1.5em;
    span {
      font-size: 1.4em;
    }
  }
  .xiclos-promotion {
    font-family: var(--xiclos-font-helv-bold);
    margin-top: 0.3em;
    font-size: 1.4em;
    .text-red {
      display: inline-block;
      border: 2px solid rgb(234, 233, 242, 0.8);
      border-radius: 9px;
      padding-top: 5px;
      padding-bottom: 4px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .xiclos-btn-suscribe {
    .btn {
      border: none;
      font-family: var(--xiclos-font-helv-medium);
      font-size: 0.9em;
      padding: 10px 30px;
      &::before {
        background: var(--xiclos-red);
        border: none;
      }
    }
  }
  .xiclos-logo {
    width: 280px;
  }
  .xiclos-catalogo {
    padding-top: 30px;
    &:hover {
      h5 {
        color: var(--xiclos-red);
      }
      i {
        color: var(--xiclos-red);
      }
      a {
        width: 100%;
        height: 100%;
      }
    }
    h5 {
      font-family: var(--xiclos-font-abel);
      font-size: 1.4em;
      color: #fff;
      &:hover {
        color: var(--xiclos-red);
        transition: all 300ms ease;
      }
    }
    i {
      font-size: 3.2em;
      color: #fff;
      &:hover {
        color: var(--xiclos-red);
        transition: all 300ms ease;
      }
    }
  }
  .director-img {
    margin: 10px;
    border-radius: 50%;
    filter: grayscale(100%);
    cursor: pointer;
  }
  .xiclos-free {
    background: linear-gradient(
      206deg,
      rgba(235, 86, 74, 0.8) 21%,
      transparent 20%
    );
  }
  .xiclos-btn {
    font-family: var(--xiclos-font-helv-medium);
    font-size: 0.9em;
    &::before {
      background: var(--xiclos-red);
    }
    &:hover {
      border-color: var(--iq-primary-hover);
    }
  }
  .xiclos-btn-inverter {
    border: none;
    color: var(--xiclos-red);
    &::before {
      background: #fff;
      border: none;
    }
  }
  .wrapper-devices {
    padding: 20px 0;
  }
  .xiclos-devices {
    h4 {
      font-size: 2.4em;
      font-family: var(--xiclos-font-helv-bold);
    }
    p {
      font-family: var(--xiclos-font-helv-bold);
      color: white;
    }
  }
  #xiclos-ver-catalogo {
    background: var(--xiclos-bg-gradient-dark);
    padding: 4em 0;
    h4 {
      font-family: var(--xiclos-font-helv-normal);
      margin: 30px 0 15px 0;
      font-size: 1.6em;
    }
    button {
      font-size: 0.9em;
      font-family: var(--xiclos-font-helv-bold);
      margin: 10px 0 30px 0;
    }
  }
  @media screen and (max-width: 1366px) {
    .home-no-usuario {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 90%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(20, 20, 20, 1) 0%,
          rgba(36, 36, 36, 1) 35%,
          rgba(83, 100, 141, 0) 100%
        );
        z-index: 1;
        background: linear-gradient(
          64deg,
          rgba(0, 0, 0, 82%) 53%,
          rgba(20, 20, 20, 92%) 35%,
          rgba(83, 100, 141, 0) 0%
        );
        width: 100%;
        z-index: -1;
      }
    }
    .red-border {
      height: 12em;
      background: linear-gradient(
        192deg,
        rgb(235, 86, 74, 82%) 40%,
        rgba(20, 20, 20, 0%) 35%,
        rgba(83, 100, 141, 0) 0%
      );
    }
    #directors-list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0.5em;
      margin-left: 5em;
      li {
        padding-right: 1em;
        margin-right: 0em;
        padding-bottom: 1em;
      }
      img {
        height: 9em;
        width: 9em;
      }
    }
  }
  @media screen and (max-width: 1201px) {
    #home-slider {
      p {
        margin: 20px 0;
        width: 55%;
        color: #ffffff;
      }
    }
    #copy {
      width: 23rem;
    }
    #directors-list {
      img {
        height: 6em;
        width: 6em;
      }
    }
    .home-no-usuario {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 90%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(20, 20, 20, 1) 0%,
          rgba(36, 36, 36, 1) 35%,
          rgba(83, 100, 141, 0) 100%
        );
        z-index: 1;
        background: linear-gradient(
          64deg,
          rgba(0, 0, 0, 82%) 62%,
          rgba(20, 20, 20, 92%) 35%,
          rgba(83, 100, 141, 0) 0%
        );
        width: 100%;
        z-index: -1;
      }
    }
    .red-border {
      height: 12em;
      background: linear-gradient(
        193deg,
        rgb(235, 86, 74, 82%) 39%,
        rgba(20, 20, 20, 92%) 35%,
        rgba(83, 100, 141, 0) 0%
      );
    }
  }
  @media screen and (max-width: 885px) {
    #home-slider {
      h1.slider-text {
        padding-top: 0.5em;
        font-size: 4em;
        margin: 15px 0;
      }
    }
    .red-border {
      height: 12em;
      background: linear-gradient(
        193deg,
        rgb(235, 86, 74, 82%) 44%,
        rgba(20, 20, 20, 92%) 35%,
        rgba(83, 100, 141, 0) 0%
      );
    }
  }
  @media screen and (max-width: 884px) {
    #home-slider {
      h1.slider-text {
        padding-top: 0.5em;
        font-size: 3.5em;
        margin: 15px 0;
      }
    }
    .p-catalogo {
      font-size: 1.2em;
    }
  }
  @media screen and (max-width: 550px) {
    .container-fluid {
      padding: 0 10px;
    }
    #home-slider {
      p {
        margin: 20px 0;
        width: 79%;
        color: #ffffff;
        margin: 15px 0;
        font-size: 1em;
        color: #ffffff;
      }
      h1.slider-text {
        padding-top: 0.9em;
        font-size: 2em;
      }
    }
    .home-no-usuario {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 90%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(20, 20, 20, 1) 0%,
          rgba(36, 36, 36, 1) 35%,
          rgba(83, 100, 141, 0) 100%
        );
        z-index: 1;
        background: linear-gradient(
          64deg,
          rgba(0, 0, 0, 82%) 72%,
          rgba(20, 20, 20, 92%) 35%,
          rgba(83, 100, 141, 0) 0%
        );
        width: 100%;
        z-index: -1;
      }
    }
    .red-border {
      height: 10em;
      background: linear-gradient(
        193deg,
        rgb(235, 86, 74, 82%) 54%,
        rgba(20, 20, 20, 92%) 35%,
        rgba(83, 100, 141, 0) 0%
      );
    }
    .xiclos-logo {
      margin-left: 0.5em;
      width: 14em;
      margin-left: 0.5em;
      width: 14em;
    }
    .div-free-button {
      padding-right: 0.5em;
      padding-bottom: 0.5em;
      padding-top: 0.5em;
    }
    .div-log-button {
      padding-right: 0.5em;
    }
    #directors-list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0.5em;
      margin-left: 5em;
      li {
        padding-right: 1em;
        margin-right: 0em;
        padding-bottom: 1em;
      }
    }
    .arrow {
      padding-top: 0em;
    }
    #down {
      a {
        i {
          font-size: 1.5em;
        }
      }
    }
  }
  @media screen and (max-width: 549px) {
    #directors-list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0.5em;
      margin-left: 1em;
    }
    .arrow {
      padding-top: 0em;
    }
    .xiclos-logo {
      margin-left: 0.5em;
      width: 8em;
    }
    #home-slider {
      p {
        margin: 15px 0;
        font-size: 1em;
        color: #ffffff;
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .no-user-header {
      font-size: 14px;
      > .row {
        padding: 0px 0px 0px 60px;
      }
    }
    .xiclos-free {
      background: linear-gradient(
        208deg,
        rgba(235, 86, 74, 0.8) 25%,
        transparent 20%
      );
    }
    .xiclos-catalogo {
      padding-top: 15px;
    }
    .cineclub-directors-description {
      padding-left: 5em !important;
    }
  }
  @media screen and (max-width: 800px) {
    .no-user-header {
      font-size: 12px;
      background-position-x: center;
      > .row {
        padding: 0px 0px 0px 20px;
      }
      &::before {
        background: rgba(0, 0, 0, 0.8);
      }
    }
    .xiclos-mobile-login {
      display: block !important;
    }
    #logo-no-user {
      max-width: 250px;
    }
    .xiclos-title {
      h1 {
        margin-top: 1.4em;
      }
    }
    .xiclos-description {
      font-size: 1.5em;
    }
    .xiclos-price {
      margin-top: 2em;
    }
    .xiclos-btn-suscribe {
      margin-top: 3em;
    }
    .show-movie-detail-section-tras {
      .main-title {
        padding: 0px 50px 0 40px !important;
        &::before {
          left: 15px;
          top: 0%;
          transform: translateY(30%);
        }
      }
    }
    .director-img {
      max-width: 40%;
      margin-bottom: 50px;
    }
    .cineclub-directors {
      background-size: 150% 100%;
      background-position: center;
    }
    .cineclub-directors-description {
      padding-left: 3em !important;
      margin-bottom: 30px;
    }
    .wrapper-devices {
      text-align: center;
      h4 {
        font-size: 1.7em;
      }
    }
    #xiclos-ver-catalogo {
      font-family: var(--xiclos-font-helv-medium);
      font-size: 0.8em;
    }
  }
}
