.explore {
  //   body {
  //     background: var(--xiclos-bg-gradient-dark);
  //     font-family: var(--xiclos-font-helv-normal);
  //   }
  .first-section {
    padding-top: 100px;
    color: var(--iq-white);
  }
  .xiclo-title {
    i {
      font-size: 3em;
    }
    h1 {
      font-size: 1.5em;
    }
  }
  .explore-tax-title {
    font-size: 1.1em;
    font-family: "Helvetica Neue Bold";
    color: var(--iq-white);
  }
  .explore-tax-list {
    .divLoading {
      min-height: auto !important;
      height: 50px !important;
      i {
        padding: 0 5px;
      }
      .loading-content {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      padding-top: 10px;
    }
  }
  .explore-tax-item {
    margin: 0px 5px 10px 5px;
    padding: 6px 15px 3px 15px;
    border: 1px solid #fff;
    border-radius: 6px;
    font-size: 0.9em;
    cursor: pointer;
    &:hover {
      background: var(--xiclos-red);
      border: 1px solid var(--xiclos-red);
      transition: background, border 300ms ease;
    }
  }
  .see-more {
    padding: 6px 15px 3px 15px;
    font-size: 0.9em !important;
    border: none;
    outline: none;
    margin: 0px 5px 10px 10px;
    border-radius: 5px;
    color: white;
    background: var(--xiclos-red);
    font-family: "Helvetica Neue Bold";
    cursor: pointer;
    transition: all 300ms ease;
    &:hover {
      color: var(--xiclos-red);
      background: white;
    }
  }
  .explore-tax-active {
    background: var(--xiclos-red);
    border: 1px solid var(--xiclos-red);
    transition: background, border 300ms ease;
  }
  .explore-clean {
    padding: 5px 15px 3px 15px;
    font-size: 1.1em;
    font-family: var(--xiclos-font-helv-bold);
    background: none;
    outline: none;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    transition: background, color 300ms ease;
    margin-top: 20px;
    &:hover {
      background: #fff;
      border: 2px solid #fff;
      color: var(--xiclos-red);
    }
  }
  .check-icon {
    font-weight: bolder;
    background-color: #6fcf97;
    color: white;
    font-size: 2em;
    border-radius: 32px;
    width: 2em;
    height: 2em;
    margin-bottom: 0.5em;
    padding: 8px 16px 14px;
  }
  .alert-fixed.alert-fixed {
    position: fixed;
    border-radius: 5px !important;
    background-color: #4caf50;
    top: 84%;
    left: 16%;
    width: 70%;
    color: #fff;
    z-index: 9999;
    border: none !important;
  }
  .explore-max-height-posters {
    max-height: 450px !important;
  }
  @media screen and (max-width: 600px) {
    .first-section {
      padding-top: 2em;
    }
  }
}
