.reset-password {
  @keyframes rotate-vortex {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes vortexFormFadeOut {
    0% {
      visibility: visible;
      opacity: 1;
    }
    50% {
      visibility: visible;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }
  @keyframes vortexFormFadeIn {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    99% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
  /* Responsive */
  #sign-up {
    font-size: 16px !important;
    font-size: 16px;
    min-height: 100vh;
    overflow-x: hidden;
    background: linear-gradient(
      to right,
      #732323 0%,
      var(--xiclos-red) 49%,
      #732323 100%
    );
    label {
      font-size: 1em;
      color: var(--xiclos-text);
      font-family: "Helvetica Neue Regular";
    }
    input {
      font-size: 1em;
      padding: 2em !important;
      &:disabled {
        background: #eee !important;
      }
    }
    select {
      font-size: 1em !important;
      height: 60%;
      &:disabled {
        background: #eee !important;
      }
    }
    button {
      font-size: 1.2em;
    }
    .sign-in-page-container {
      z-index: 2;
    }
    footer {
      min-height: 10vh;
      height: 100% !important;
      max-height: 10vh !important;
      font-size: 1.3em;
    }
    .sign-in-page {
      min-height: 90vh;
      height: 100%;
      padding: 1.8em;
      background-image: none;
      display: flex;
      justify-content: start;
      flex-direction: column;
    }
    .sign-up-logo {
      img {
        width: 12.5em;
      }
    }
    .sign-up-header {
      h2 {
        font-family: "Abel", sans-serif;
        font-size: 4.12em;
      }
      h1 {
        font-family: "Helvetica Neue Bold", sans-serif;
        font-size: 2.5em;
      }
    }
    .sign-up-movies {
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
      height: 70%;
      z-index: 0;
      display: flex;
    }
    .sign-up-movie-1 {
      width: 50%;
      height: 100%;
      background-position: right;
      background-size: auto 100%;
      background-repeat: no-repeat;
      opacity: 0.5;
      background-image: url("../images/login/movies.png");
      transform: scaleX(-1);
    }
    .sign-up-movie-2 {
      width: 50%;
      height: 100%;
      background-position: right;
      background-size: auto 100%;
      background-repeat: no-repeat;
      opacity: 0.5;
      background-position: right;
      background-image: url("../images/login/movies.png");
    }
    .sign-up-form-container {
      padding: 1em;
    }
    input.form-control {
      border: 1px solid var(--xiclos-border-form);
      font-family: "Helvetica Neue Regular";
      &::placeholder {
        color: #aaa;
        font-size: 1em;
      }
      &:focus {
        border: 1px solid var(--xiclos-red);
      }
    }
    .sign-up-text {
      font-size: 1.4em;
      font-family: "Helvetica Neue Medium";
      color: var(--xiclos-text);
    }
  }
  #sign-in-submit {
    div {
      animation: rotate-vortex 800ms infinite;
      animation-timing-function: linear;
      transform: rotate(90deg);
      font-size: 20px;
    }
  }
  #sign-up-steps-btn {
    div {
      animation: rotate-vortex 800ms infinite;
      animation-timing-function: linear;
      transform: rotate(90deg);
      font-size: 20px;
    }
  }
  #sign-in {
    height: 100vh;
    font-size: 16px !important;
    overflow: hidden;
    background: linear-gradient(
      to right,
      #732323 0%,
      var(--xiclos-red) 49%,
      #732323 100%
    );
    footer {
      height: 100% !important;
      max-height: 10vh !important;
      font-size: 1.3em;
    }
    .sign-in-page {
      height: 100% !important;
      max-height: 90vh !important;
      background-image: url("../images/login/movies.png");
      background-position: center;
      background-size: 100% auto;
    }
    .sign-in-page-backdrop {
      height: 100% !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: linear-gradient(
        115deg,
        rgba(9, 9, 121, 0) 53%,
        rgba(0, 0, 0, 0.8) 53%
      );
    }
  }
  .log-in-page {
    height: 100% !important;
    max-height: 90vh !important;
    background-image: url("../images/login/movies.png");
    background-position: center;
    background-size: 100% auto;
  }
  .sign-up-xiclos {
    h1 {
      font-size: 2.6em;
      max-width: 450px;
      font-family: "Helvetica Neue Bold";
      color: #fff;
    }
    h2 {
      font-family: "Abel";
      font-size: 70px;
      color: #fff;
    }
    img {
      width: 250px;
      margin: 10px 0;
    }
  }
  #sign-in-back {
    position: absolute;
    top: 0;
    height: 40px;
    width: 40px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    margin: 1em;
    font-size: 2em;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    cursor: pointer;
    transition-duration: 500ms;
    &:hover {
      opacity: 0.8 !important;
    }
  }
  .sign-user_card {
    border-radius: 15px;
  }
  .sign-up-step-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0.7em 0;
    .sign-up-step {
      background: #eee;
      width: 3.25em;
      height: 3.25em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-family: "Helvetica Neue Bold";
      color: #111;
      font-size: 1.25em;
      position: relative;
      transition: all 300ms ease;
      p {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-40%);
      }
    }
    .sign-up-step-text {
      font-family: "Helvetica Neue Bold";
      font-size: 1em;
      color: #fff;
      margin-top: 0.4em;
    }
    .sign-up-step.sign-up-step-active {
      background: var(--xiclos-red);
      color: #fff;
    }
  }
  .sign-up-step-line {
    width: 80%;
    height: 5px;
    background: #ddd;
    align-self: center;
    border-radius: 2px;
  }
  .sign-title {
    color: var(--xiclos-title);
    font-family: "Helvetica Neue Bold";
    font-size: 2.6em;
  }
  .btn {
    font-family: "Helvetica Neue Regular";
  }
  .sign-in-page-p {
    font-family: "Helvetica Neue Regular";
    font-size: 1em;
    font-weight: normal;
    color: var(--xiclos-text);
  }
  div.form-group {
    input.form-control {
      font-family: "Helvetica Neue Regular";
      color: rgb(85, 85, 85);
    }
  }
  .form-group {
    select.form-control {
      font-family: "Helvetica Neue Regular";
      color: rgb(85, 85, 85);
    }
  }
  #sign-up-country {
    option {
      color: var(--xiclos-red);
      font-size: 1em;
      &:checked {
        background: var(--xiclos-red);
        color: #fff;
      }
    }
    &:focus {
      color: var(--xiclos-title);
    }
  }
  .sign-up-description {
    h3 {
      color: var(--xiclos-title);
      font-family: "Helvetica Neue Bold";
      font-size: 2.8em;
    }
    h4 {
      color: var(--xiclos-title);
      font-family: "Helvetica Neue Bold";
      font-size: 1em;
    }
    p {
      font-size: 0.8em;
    }
    span {
      color: var(--xiclos-red);
    }
  }
  #sign-up-coupon {
    padding: 0px 32px !important;
    font-size: 1em;
  }
  .sign-up-coupon {
    .container-fluid {
      width: 100% !important;
      padding: 0 20px;
    }
    p {
      font-size: 0.9em;
    }
  }
  button.btn {
    font-family: "Helvetica Neue Bold";
  }
  button.btn.btn-link {
    padding: 0;
    margin: 0;
    color: var(--xiclos-red);
    &:hover {
      color: var(--xiclos-red-dark);
      text-decoration: underline;
    }
  }
  #sign-up-form {
    position: relative;
  }
  .sign-in-page {
    .btn {
      margin: 0 !important;
      padding: 1em !important;
      width: 100%;
      height: 100%;
    }
  }
  .sign-up-errors {
    display: none;
    color: var(--xiclos-red);
    text-align: center;
  }
  .sign-up-checkbox-container {
    color: var(--xiclos-text);
    font-family: "Helvetica Neue Regular";
    a {
      font-family: "Helvetica Neue Medium";
      color: var(--xiclos-red) !important;
    }
  }
  .sign-up-steps-btn {
    transition: all 300ms ease;
  }
  #sign-up-btn-terms {
    background: none;
    border: none;
    color: var(--xiclos-red);
    padding: 0 !important;
    font-size: 1em !important;
    font-family: var(--xiclos-font-helv-bold);
    cursor: pointer;
  }
  #sign-up-terms-modal-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 998;
  }
  #sign-up-payment-result-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 998;
    position: fixed;
  }
  #sign-up-terms-modal {
    position: absolute;
    transition: all 300ms ease;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    padding: 1em;
    height: 100%;
    max-height: 80vh;
    background: #fff;
    z-index: 999;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    max-width: 600px;
    h3 {
      color: var(--xiclos-red);
      text-align: center;
      font-family: var(--xiclos-font-helv-bold);
      font-size: 1.7em !important;
      padding: 15px 0;
    }
  }
  #sign-up-payment-result {
    position: absolute;
    transition: all 300ms ease;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    padding: 1em;
    background: #fff;
    z-index: 999;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    max-width: 330px;
    h3 {
      color: var(--xiclos-red);
      text-align: center;
      font-family: var(--xiclos-font-helv-bold);
      font-size: 1.2em !important;
      padding: 15px 0 10px 0;
    }
    hr {
      border: 0.1px solid rgba(0, 0, 0, 0.05) !important;
      height: 0px;
      width: 100%;
      margin: 0px 0px 10px 0px;
    }
    p {
      text-align: center;
      color: var(--xiclos-text);
    }
    button {
      border-radius: 5px;
      text-transform: uppercase;
      font-size: 0.9em !important;
      padding: 5px 10px;
      font-family: var(--xiclos-font-helv-medium);
      color: #fff;
      background: var(--xiclos-red);
      border: none;
      cursor: pointer;
      margin-top: 20px;
      margin: 0px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  #sign-up-terms-text {
    height: 100%;
    overflow-y: scroll;
    padding: 0 2em;
    color: var(--xiclos-title);
    font-family: var(--xiclos-font-helv-normal);
    b {
      font-family: var(--xiclos-font-helv-medium);
    }
  }
  #sign-up-terms-close {
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 0.9em !important;
    padding: 5px 10px;
    font-family: var(--xiclos-font-helv-medium);
    color: #fff;
    background: var(--xiclos-red);
    border: none;
    cursor: pointer;
    margin-top: 20px;
    &:hover {
      opacity: 0.8;
    }
  }
  .sign-up-credit-card {
    width: 100%;
    background-image: url("../images/login/credit-card-example.png");
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .sign-up-info {
    text-align: center;
    color: var(--xiclos-text);
    margin: 15px 0;
    span {
      color: var(--xiclos-red);
      font-weight: 600;
    }
  }
  .card-wrapper {
    width: auto !important;
  }
  .jp-card {
    min-width: auto !important;
  }
  .sign-up-check {
    font-size: 1em !important;
    span {
      color: var(--xiclos-red);
      font-family: "Helvetica Neue Bold";
    }
  }
  .sign-up-welcome {
    h4 {
      color: var(--xiclos-text);
      font-family: "Helvetica Neue Bold";
      margin-bottom: 10px !important;
    }
    img {
      max-width: 380px;
    }
  }
  .sign-up-now {
    h2 {
      font-family: "Helvetica Neue Bold";
      font-size: 2.8em;
      color: var(--xiclos-text);
    }
  }
  .sign-up-small {
    color: var(--xiclos-text);
    font-size: 90% !important;
    font-family: var(--xiclos-font-helv-normal);
  }
  .vortexFadeOut {
    animation-name: vortexFormFadeOut;
    animation-duration: 300ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
  .vortexFadeIn {
    animation-name: vortexFormFadeIn;
    animation-duration: 300ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
  .user-information-recovery {
    background-color: #eb564a;
    color: #ffffff;
    a {
      text-decoration: underline !important;
      color: #ffffff !important;
      &:hover {
        text-decoration: underline;
        color: #000000 !important;
      }
    }
  }
  @media screen and (min-width: 1300px) {
    sign-up-xiclos {
      h1 {
        font-size: 50px;
        max-width: 450px;
        font-family: "Helvetica Neue Bold";
        color: #fff;
      }
    }
    .sign-up-xiclos {
      h2 {
        font-family: "Abel";
        font-size: 80px;
        color: #fff;
      }
      img {
        width: 300px;
        margin: 10px 0;
      }
    }
  }
  @media screen and (max-width: 800px) {
    #sign-in {
      .sign-in-page {
        background-image: url("../images/login/movies.png") !important;
        background-position: cover;
        background-size: auto 100%;
      }
    }
  }
  @media screen and (max-width: 600px) {
    #sign-in {
      font-size: 14px;
    }
    .text-footer {
      font-size: 0.8em;
    }
    #sign-up {
      font-size: 12px !important;
      .sign-in-page {
        padding: 1em;
      }
      .container-fluid {
        padding: 0;
      }
      footer.container-fluid {
        padding: 0 10px;
      }
      .sign-up-form-container {
        padding: 10px 0;
      }
    }
    html {
      font-size: 12px !important;
    }
    .sign-up-step-line {
      width: 100%;
      max-width: 60px;
      height: 3px;
    }
    .sign-up-form-container {
      margin-top: 25px !important;
    }
    .sign-up-welcome {
      img {
        max-width: 200px;
      }
    }
    .sign-up-now {
      h2 {
        font-family: "Helvetica Neue Bold";
        font-size: 2.6em;
        color: var(--xiclos-text);
      }
    }
  }
  @media screen and (min-width: 1600px) {
    #sign-up {
      font-size: 16px !important;
    }
    #sign-in {
      font-size: 16px !important;
    }
    .sign-up-form-container {
      max-width: 1000px;
    }
  }
  @media screen and (max-width: 1400px) {
    #sign-up {
      font-size: 14px !important;
      .sign-up-logo {
        img {
          width: 150px;
        }
      }
      .sign-up-header {
        h2 {
          font-family: "Abel", sans-serif;
          font-size: 3em;
        }
        h1 {
          font-family: "Helvetica Neue Bold", sans-serif;
          font-size: 1.8em;
        }
      }
    }
    #sign-in {
      font-size: 14px !important;
    }
    #sign-up-country {
      height: 50px;
      padding: 0 20px;
    }
    #sign-up-coupon {
      height: 40px;
    }
  }
}
