.cine-club {
  #cineclub {
    background: var(--xiclos-bg);
    font-size: 16px;
    margin-left: 0em;
    margin-right: 0em;
  }
  .space-block {
    padding: 8rem 0 5rem 0;
  }
  .first-section-text {
    text-align: center;
    font-size: 30px;
    font-family: "Abel";
    padding-top: 3rem;
    color: #fff;
  }
  .xiclos-row-red {
    width: 100%;
    height: 7rem;
    margin: 40px 0 42px;
    background-image: linear-gradient(
      to right,
      #732323 0%,
      var(--xiclos-red) 49%,
      #732323 100%
    );
    align-items: center;
  }
  .xiclos-row-red-elements-position {
    position: absolute;
    margin-left: 2rem;
    align-items: center;
  }
  .xiclos-image-profile {
    height: 8rem;
    position: absolute;
    width: 8rem;
  }
  .xiclos-movies {
    justify-content: space-around;
    flex-wrap: wrap;
    display: flex;
    align-content: flex-start;
    margin-left: 0;
  }
  .xiclos-movies-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
  li.slide-item-cine-club {
    margin: 20px 35px 3px 0px;
    width: 16rem;
    padding: 0px 0px !important;
  }
  .cine-club-title {
    background-image: url("../images/icons/xiclos-icon-red@3x.png");
    background-repeat: no-repeat;
    background-position: 0px 3px;
    padding-left: 1em;
    color: white;
    margin-left: 9rem;
    background-size: 1.2em;
    h4 {
      font-family: "Abel";
      font-size: 1.5em;
    }
  }
  .xiclo-title {
    i {
      font-size: 3em;
    }
    h3 {
      font-size: 1.5em;
    }
  }
  .check-icon {
    font-weight: bolder;
    background-color: #6fcf97;
    color: white;
    font-size: 2em;
    border-radius: 32px;
    width: 2em;
    height: 2em;
    margin-bottom: 0.5em;
    padding: 8px 16px 14px;
  }
  .alert-fixed.alert-fixed {
    position: fixed;
    border-radius: 5px !important;
    background-color: #4caf50;
    top: 84%;
    left: 16%;
    width: 70%;
    color: #fff;
    z-index: 9999;
    border: none !important;
  }
  @media screen and (max-width: 1091px) {
    #copy {
      width: 23rem;
    }
  }
  @media screen and (max-width: 800px) {
    .space-block {
      padding: 2rem 0 3rem 0;
    }
  }
}
