#front-page-container {
  height: 90vh;
}

#home {
  height: 100%;
}

#home-slider {
  height: 100%;
}

@media screen and (max-width: 960px) {
  #front-page-container {
    height: 100%;
  }
}

/** CAROUSEL CON SWIPER ***/
.swiper-container {
  overflow: initial !important;
}
/* .swiper-button-prev {
  color: white;
  background-color: var(--xiclos-red);
  border-radius: 50%;
  padding-right: 15px;
  width: 3.2em;
  padding-left: 18px;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 3.2em;
}

.swiper-button-prev::after {
  font-size: 24px;
  font-weight: bold;
}

.swiper-button-next {
  color: white;
  background-color: var(--xiclos-red);
  border-radius: 50%;
  padding-right: 15px;
  width: 3.2em;
  padding-left: 18px;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 3.2em;
}

.swiper-button-next::after {
  font-size: 24px;
  font-weight: bold;
}
#home-slider .swiper-container {
  overflow: hidden;
}
*/

#home-slider .swiper-container .swiper-slide {
  height: auto;
}

.swiper-container {
  z-index: 3 !important;
  overflow: unset;
}

.swiper-wrapper {
  z-index: 4 !important;
}

.swiper-slide {
  z-index: 5 !important;
}

.swiper-slide:hover {
  z-index: 999 !important;
}
/*************************/
.vortex-slides-title {
  font-family: var(--xiclos-font-abel);
  font-size: 2.2em;
}
.vortex-slides {
  width: 100%;
}

.vortex-slides-pull .vortex-film {
  height: 490px;
}

.vortex-film-small-device {
  height: 490px;
}

.vortex-film {
  padding: 0.3em;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: all 300ms ease;
  border-radius: 6px;
  width: 100%;
  height: 360px !important;
}

.vortex-film-small-device {
  padding: 0.3em !important;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: all 300ms ease;
  border-radius: 6px;
  width: 100%;
  height: 435px !important;
}

.vortex-film-img {
  height: 100% !important;
  width: 100% !important;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.vortex-film-img img {
  height: 100%;
  width: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.vortex-film-description {
  position: absolute;
  top: 50%;
  transform: translateY(-45%);
  left: 0;
  visibility: hidden;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-family: var(--xiclos-font-helv-bold);
}

.vortex-description-img {
  width: 100%;
  height: 100%;
}

.vortex-description-title {
  font-size: 1.1em;
  padding-bottom: 0.4em;
}

.vortex-description-tax {
  padding: 5px 0;
}

.vortex-description-tax-text {
  font-size: 0.8em;
}

.vortex-description-tax-options {
  color: var(--xiclos-red);
}

.vortex-description-see-movie {
  width: 100%;
  font-size: 0.9em !important;
  padding: 8px 6px !important;
  font-family: var(--xiclos-font-helv-medium);
  border: none;
}

.vortex-description-see-more {
  width: 100%;
  font-size: 0.9em;
  background: none;
  border: none;
  color: #fff;
  padding: 0px;
  cursor: pointer;
}

.vortex-description-see-more:hover {
  color: #fff;
  opacity: 0.8;
}

.vortex-description-add-fav {
  border: 1px solid #fff;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  background: none;
  color: #fff;
  font-family: var(--xiclos-font-abel);
  font-weight: 600;
}

.vortex-description-sinopsis {
  font-size: 0.85em;
  margin-top: 20px;
}

.vortex-description-sinopsis-text {
  font-size: 0.75em;
  font-family: var(--xiclos-font-helv-normal);
}

.vortex-description-tag {
  padding-right: 15px;
  font-size: 0.85em;
  margin: 0 !important;
}

.vortex-description-pg {
  margin: 0;
  font-size: 0.85em;
  border-radius: 3px;
  border: 1px solid #fff;
  padding: 3px 3px 1px 3px;
}

.vortex-description-imdb {
  border: 1px solid #fff;
  padding: 3px 3px 1px 3px;
  font-family: var(--xiclos-font-helv-normal);
  outline: none;
  border-radius: 3px;
  margin: 0;
  font-size: 0.85em;
}

.vortex-film:hover {
  position: relative;
  z-index: 99;
  background: rgba(26, 26, 26, 0.9);
  display: flex;
}

.vortex-film:hover .vortex-film-img img {
  opacity: 0.1;
}

.vortex-film:hover .vortex-film-description {
  visibility: visible;
  height: 100%;
  color: #fff;
  z-index: 9999;
}

.vortex-film:hover .vortex-no-scale {
  position: absolute;
  width: 140%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0%;
  left: 0%;
  z-index: 999;
  right: auto;
  background: #000000dd;
  box-shadow: var(--xiclos-shadow);
  border-radius: 6px;
  transition: all ease 300ms;
}

/* Responsive */

.vortex-description-img img {
  width: auto;
}

@media screen and (min-width: 1367px) {
  .vortex-slides-pull .vortex-film {
    height: auto !important;
  }
  .vortex-film:hover .vortex-no-scale {
    width: 440px !important;
    height: auto !important;
    top: 0% !important;
  }
  .vortex-description-img img {
    width: 100%;
  }
}

@media screen and (min-width: 1560px) {
  .vortex-slides-pull .vortex-film {
    height: auto !important;
  }
  .vortex-film {
    height: 360px !important;
  }
  .vortex-film-small-device {
    height: 510px !important;
  }
  .vortex-film:hover .vortex-no-scale {
    width: 480px !important;
    height: auto !important;
    top: 0% !important;
  }
}

@media screen and (min-width: 1600px) {
  .vortex-film {
    height: 400px !important;
  }
}

@media screen and (max-width: 1367px) {
  .vortex-slides-pull .vortex-film:hover .vortex-no-scale {
    width: 250%;
  }
  .vortex-film {
    height: 310px !important;
  }
  .vortex-film-small-device {
    height: 400px !important;
  }
  .vortex-slides-pull .vortex-film-small-device {
    height: auto !important;
  }
  .vortex-slides-pull .vortex-film {
    height: auto !important;
  }
  .vortex-film:hover .vortex-no-scale {
    width: 440px;
    top: -5%;
  }
}

@media screen and (max-width: 1280) {
  .vortex-film {
    height: 300px !important;
  }
  .vortex-film-small-device {
    height: 300px !important;
  }
  .vortex-film:hover .vortex-no-scale {
    width: 230%;
    top: -5%;
  }
}
@media screen and (max-width: 1080px) {
  .vortex-slides-pull .vortex-film {
    height: 250px;
  }
  .vortex-slides-pull .vortex-film-small-device {
    height: 250px !important;
  }
  .vortex-film {
    height: 250px;
  }
  .vortex-film-small-device {
    height: 250px !important;
  }
  .vortex-film:hover .vortex-no-scale {
    width: 420px;
  }
}

@media screen and (max-width: 769px) {
  .swiper-container .vortex-film {
    height: 250px;
  }
  .swiper-container .vortex-film-small-device {
    height: 250px !important;
  }

  .swiper-container .vortex-film:hover .vortex-no-scale {
    width: 310%;
  }

  .vortex-slides-pull .vortex-film {
    height: auto !important;
  }

  .vortex-slides-pull .vortex-film-small-device {
    height: auto !important;
  }

  .vortex-slides-pull .vortex-film:hover .vortex-no-scale {
    width: 350% !important;
  }
}

@media screen and (min-width: 600px) {
  .vortex-slides-2 .vortex-film:hover:nth-child(2n) .vortex-no-scale {
    left: auto !important;
    right: 0% !important;
  }

  /* .vortex-slides-4 .vortex-film:hover .vortex-no-scale {
        right:0% !important;
        left:50% !important;
        transform: translateX(-50%) !important;
    }

    .vortex-slides-4 .vortex-film:hover:nth-child(4n + 1) .vortex-no-scale {
        transform: translateX(0%) !important;
        left:auto !important;
        right:0% !important;
    }

    .vortex-slides-4 .vortex-film:hover:nth-child(4n + 2) .vortex-no-scale {
        transform: translateX(0%) !important;
        right:auto !important;
        left:0% !important;
    } */
  .vortex-slides-pull .vortex-film:hover .vortex-no-scale {
    right: 0% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    height: auto;
    width: 430px;
    top: -10%;
    transform: translateY(0%);
  }

  .vortex-slides-pull .vortex-film:hover:nth-child(6n) .vortex-no-scale {
    transform: translateX(0%) !important;
    left: auto !important;
    right: -15px !important;
  }

  .vortex-slides-pull .vortex-film:hover:nth-child(6n + 1) .vortex-no-scale {
    transform: translateX(0%) !important;
    right: auto !important;
    left: -15px !important;
  }

  .vortex-slides .vortex-film.vortex-center:hover .vortex-no-scale {
    right: 0% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .vortex-slides .vortex-film.vortex-left:hover .vortex-no-scale {
    transform: translateX(0%) !important;
    left: 0px !important;
    right: auto !important;
  }

  .vortex-slides .vortex-film.vortex-right:hover .vortex-no-scale {
    transform: translateX(0%) !important;
    right: 0px !important;
    left: auto !important;
  }

  .vortex-slides-pull .vortex-film-explore:hover .vortex-no-scale {
    right: 0% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .vortex-slides-pull
    .vortex-film-explore:hover:nth-child(3n + 1)
    .vortex-no-scale {
    transform: translateX(0%) !important;
    right: auto !important;
    left: -15px !important;
  }

  .vortex-slides-pull
    .vortex-film-explore:hover:nth-child(3n)
    .vortex-no-scale {
    transform: translateX(0%) !important;
    left: auto !important;
    right: -15px !important;
  }
  /* .swiper-wrapper .swiper-slide .vortex-film:hover .vortex-no-scale {
        transform: translateX(-50%) !important;
        right:0% !important;
        left:50% !important;
    }


    .swiper-wrapper .swiper-slide:nth-child(6n) .vortex-film:hover .vortex-no-scale {
        transform: translateX(0%) !important;
        right:0px !important;
        left:auto !important;
    }

    .swiper-wrapper .swiper-slide:nth-child(6n +1) .vortex-film:hover .vortex-no-scale {
        transform: translateX(0%) !important;
        right:auto !important;
        left:0px !important;
    } */

  .swiper-wrapper .swiper-slide-active .vortex-film:hover .vortex-no-scale {
    transform: translateX(0%) !important;
    right: auto !important;
    left: 0% !important;
  }
  .swiper-wrapper
    .swiper-slide-active
    ~ .swiper-slide
    .vortex-film:hover
    .vortex-no-scale {
    transform: translateX(-50%) !important;
    right: 0% !important;
    left: 50% !important;
  }
  .swiper-wrapper
    .swiper-slide-active
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    .vortex-film:hover
    .vortex-no-scale {
    transform: translateX(0%) !important;
    right: 45px !important;
    left: auto !important;
  }
}

@media screen and (min-width: 1500px) {
  .vortex-slides-pull .vortex-film:hover:nth-child(6n) .vortex-no-scale {
    right: 0% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .vortex-slides-pull .vortex-film:hover:nth-child(6n + 1) .vortex-no-scale {
    right: 0% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .vortex-slides-pull .vortex-film:hover:nth-child(7n) .vortex-no-scale {
    transform: translateX(0%) !important;
    left: auto !important;
    right: -15px !important;
  }

  .vortex-slides-pull .vortex-film:hover:nth-child(7n + 1) .vortex-no-scale {
    transform: translateX(0%) !important;
    right: auto !important;
    left: -15px !important;
  }
}

@media screen and (min-width: 1200px) {
  .swiper-wrapper
    .swiper-slide-active
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    .vortex-film:hover
    .vortex-no-scale {
    transform: translateX(-50%) !important;
    right: 0% !important;
    left: 50% !important;
  }

  .swiper-wrapper
    .swiper-slide-active
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    .vortex-film:hover
    .vortex-no-scale {
    transform: translateX(0%) !important;
    right: 45px !important;
    left: auto !important;
  }
}

@media screen and (min-width: 1800px) {
  .swiper-wrapper
    .swiper-slide-active
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    .vortex-film:hover
    .vortex-no-scale {
    transform: translateX(-50%) !important;
    right: 0% !important;
    left: 50% !important;
  }

  .swiper-wrapper
    .swiper-slide-active
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    .vortex-film:hover
    .vortex-no-scale {
    transform: translateX(-50%) !important;
    right: 0% !important;
    left: 50% !important;
  }

  .swiper-wrapper
    .swiper-slide-active
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    + .swiper-slide
    .vortex-film:hover
    .vortex-no-scale {
    transform: translateX(0%) !important;
    right: 45px !important;
    left: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .vortex-slides-pull .vortex-film:hover .vortex-no-scale {
    width: 90vw !important;
  }

  .vortex-film {
    padding: 0.2em !important;
    height: 190px !important;
    max-height: 200px !important;
    position: initial;
    overflow: hidden;
  }

  .vortex-film-small-device {
    padding: 0.2em !important;
    height: 190px !important;
    max-height: 200px !important;
    position: initial;
    overflow: hidden;
  }

  .vortex-film-img {
    height: 100% !important;
    width: 100% !important;
    position: relative;
    background: black;
  }

  .vortex-film-img img {
    height: 100%;
    width: auto;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .vortex-film:hover .vortex-film-img img {
    opacity: 1;
  }

  .vortex-film:hover {
    transform: none;
    overflow: visible;
  }

  .vortex-film:hover .vortex-no-scale {
    position: absolute;
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: -80% !important;
    background: #000000cc;
    padding: 20px;
    border-radius: 6px;
  }
  /* 
    .vortex-film-left .vortex-no-scale  {
        transform: translateX(0%) !important;
        left:0 !important;
        right:auto !important;
    } */
  .vortex-slides-pull .vortex-film:nth-child(3n + 2):hover .vortex-no-scale {
    right: 0% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .vortex-slides-pull .vortex-film:hover:nth-child(3n + 1) .vortex-no-scale {
    transform: translateX(0%) !important;
    left: -15px !important;
    right: auto !important;
  }

  .vortex-slides-pull .vortex-film:hover:nth-child(3n) .vortex-no-scale {
    transform: translateX(0%) !important;
    right: -15px !important;
    left: auto !important;
  }

  /* .vortex-slides .slick-slide.vortex-film:nth-child(3n + 2):hover .vortex-no-scale {
        right:0% !important;
        left:50% !important;
        transform: translateX(-50%) !important;
    }

    .vortex-slides .slick-slide.vortex-film:hover:nth-child(3n + 1) .vortex-no-scale {
        transform: translateX(0%) !important;
        left:-15px !important;
        right:auto !important;
    }

    .vortex-slides .slick-slide.vortex-film:hover:nth-child(3n) .vortex-no-scale {
        transform: translateX(0%) !important;
        right:-15px !important;
        left:auto !important;
    } */

  /* .vortex-slides .vortex-film.vortex-center:hover .vortex-no-scale {
        right:0% !important;
        left:50% !important;
        transform: translateX(-50%) !important;
    }

    .vortex-slides .vortex-film.vortex-left:hover .vortex-no-scale {
        transform: translateX(0%) !important;
        left:-15px !important;
        right:auto !important;
    }

    .vortex-slides .vortex-film.vortex-right:hover .vortex-no-scale {
        transform: translateX(0%) !important;
        right:-15px !important;
        left:auto !important;
    }
     */
  /* .vortex-slides .vortex-film:hover .vortex-no-scale {
        right:0% !important;
        left:50% !important;
        transform: translateX(-50%) !important;
    } */
}
