.watch-video {
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
  #video-container {
    height: 100vh !important;
  }
  #watch-video-back {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    z-index: 999999;
    margin: 0.5em;
    font-size: 4em;
    opacity: 0;
    cursor: pointer;
    transition-duration: 500ms;
    &:hover {
      opacity: 0.8 !important;
    }
  }
  #watch-video-back.watch-video-fadeOut {
    animation: fadeOut 1500ms ease-in-out;
    animation-delay: 0;
    animation-fill-mode: forwards;
  }
  .jwplayer.jw-flag-aspect-mode {
    height: 100% !important;
  }
}
