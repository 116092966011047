.movie-details {
  .movie-title {
    font-family: "Abel" !important;
    font-size: 3.75em;
  }
  .detail-text {
    font-size: 18px;
  }
  .slide.slick-bg {
    &::before {
      z-index: 9 !important;
      background: linear-gradient(
        64deg,
        rgba(0, 0, 0, 92%) 52%,
        rgba(20, 20, 20, 92%) 35%,
        rgba(83, 100, 141, 0) 35%,
        rgba(83, 100, 141, 0) 100%
      ) !important;
    }
    div {
      z-index: 10 !important;
    }
  }
  .slider-inner {
    padding: 2% 5% 5% 5%;
  }
  .container-fluid {
    padding: 0 20px;
  }
  .movie-awards-title {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    h4 {
      color: black;
      font-family: "Helvetica Neue Bold";
    }
  }
  .icon-title {
    background-image: url("../images/xiclos-slider-title-black.png");
    background-repeat: no-repeat;
    display: block;
    height: 20px;
    width: 20px;
  }
  .movie-awards-section {
    display: flex;
    background-color: white;
    font-family: "Abel";
    padding: 0 0 0 6%;
    max-height: 100%;
    min-height: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .movie-awards-list {
    padding: 0;
    list-style: none;
  }
  @media screen and (max-width: 600px) {
    .slider {
      background: rgba(20, 20, 20, 60%);
      background-size: auto 100% !important;
      padding-top: 0.6em !important;
    }
    .slick-bg {
      background: rgba(20, 20, 20, 80%);
    }
    .slide.slick-bg {
      &::before {
        background: none !important;
      }
    }
    .slider-inner {
      padding: 10% 5%;
      background: none;
      > div {
        background: none;
      }
    }
    .container-fluid {
      padding: 0px;
    }
    .movie-awards-section {
      padding: 3% 7% 7% 7% !important;
    }
  }
}
